import axios from "axios";
import http from "../../utils/Api";
import { loginType, registerType } from "../actionTypes/types";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router";
import { getUsersPermission } from "./usersActions";
import { toast } from "react-toastify";

const signin = (data) => async (dispatch) => {
  try {
    const res = await http.post("/api/auth/gameStudio/login", data);
    if (res) {
      dispatch({
        type: "login",
        payload: res,
      });
    }
    return res;
  } catch (err) {
    console.log(err);
  }
};

const signup = (data) => async (dispatch) => {
  try {
    const dat = await http.post("/api/auth/gameStudio/register", data);
    return dat;
  } catch (err) {
    console.log(err);
  }
};
const signinGSWithGoogle = (data) => async (dispatch) => {
  try {
    const res = await http.post("/api/auth/gameStudio/loginWithGoogle", data);
    if (res) {
      dispatch({
        type: "loginWithGoogle",
        payload: res,
      });
    }
  } catch (err) {
    console.log(err);
  }
};
const forgetPasswordApi = (data) => async (dispatch) => {
  try {
    const res = await http.post("/api/auth/gameStudio/forgotPassword", data);
    if (res) {
      dispatch({
        type: "forgetPassword",
        payload: res,
      });
      toast(res.message, res.success);
    }
  } catch (err) {
    console.log(err);
  }
};
const confirmedPassword = (data, token) => async (dispatch) => {
  try {
    const res = await http.put(
      `/api/auth/gameStudio/resetPassword/${token}`,
      data
    );
    if (res) {
      dispatch({
        type: "forgetPassword",
        payload: res,
      });
      toast(res.message, res.success);
    }
  } catch (err) {
    console.log(err);
  }
};

export {
  signin,
  signup,
  signinGSWithGoogle,
  forgetPasswordApi,
  confirmedPassword,
};
