import React from 'react';

const WalletEjectionGuide = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Wallet Ejection Guide</h1>
      <h2 style={styles.subheader}>Overview</h2>
      <p>
        The “Eject Wallet” feature lets you transfer control of your wallet outside of Earnscape. 
        This gives you full ownership of your assets and their security but comes with important 
        responsibilities. Please read carefully to understand the process and its impact on your wallet 
        and assets.
      </p>

      <h2 style={styles.subheader}>What Happens When You Eject Your Wallet?</h2>
      <ol>
        <li>
          <strong>Exposure of Your Seed Phrase:</strong> Ejecting your wallet will display your 12-word seed phrase. Your seed phrase is the only way to access your wallet and assets—treat it with utmost care and confidentiality.
          <ul style={styles.warningList}>
            <li>It is <strong>HIGHLY recommended</strong> to write your seed phrase down and store it safely. Do not save electronic copies. Do not take a picture. Do not let others see your seed phrase. Store it in a place where it will not be damaged.</li>
          </ul>
        </li>
        <li>
          <strong>Self-Responsibility for Security:</strong> Once you eject, Earnscape will no longer secure your wallet or assets. You will be fully responsible for protecting your wallet, including ensuring that your seed phrase is safely stored and protected from theft or loss.
        </li>
        <li>
          <strong>Transaction Fees (Gas Fees):</strong> Earnscape will no longer guarantee your gas fees are covered. All transaction costs, including future transfers, interactions with dApps, and other transactions, will be your responsibility. If a transaction fails due to low gas, you must add gas to your wallet after you have ejected it.
        </li>
      </ol>

      <h2 style={styles.subheader}>Important Warnings</h2>
      <ul style={styles.warningList}>
        <li><strong>Do Not Proceed Unless Confident:</strong> Ejecting is irreversible. It’s recommended only for advanced users who understand how to manage a blockchain wallet independently.</li>
        <li><strong>Write Down Your Seed Phrase:</strong> When ejected, write down your seed phrase exactly as shown. Do not store it digitally or share it online, as this exposes it to potential hackers.</li>
        <li><strong>Risk of Loss:</strong> Misplacing or exposing your seed phrase can result in permanent loss of your assets. Additionally, connecting to unknown services or dApps can increase the risk of malicious activity on your wallet.</li>
      </ul>

      <h2 style={styles.subheader}>Steps to Eject Your Wallet</h2>
      <ol>
        <li>Go to Wallet Settings in your Earnscape app.</li>
        <li>Select <span style={styles.highlight}>Eject Wallet</span> (highlighted in red).</li>
        <li>
          Review the warning message:
          <ul>
            <li>Check the box to confirm that you understand and accept the consequences.</li>
            <li>Select Eject to proceed.</li>
          </ul>
        </li>
        <li>Read and complete the confirmation by selecting all statements that apply (Hint: select statements relevant to your responsibilities).</li>
      </ol>

      <h2 style={styles.subheader}>Frequently Asked Questions (FAQs)</h2>
      <ul>
        <li><strong>Can I re-import my wallet to Earnscape after ejecting?</strong>
          <p>You can never change your Earnscape wallet. Even after ejecting, your Earnscape account will continue to use the same wallet. Changing your wallet would break your account.</p>
        </li>
        <li><strong>Will I still receive support from Earnscape after ejecting my wallet?</strong>
          <p>While Earnscape is here to help answer questions, security of your wallet and transaction fees are entirely your responsibility after ejection.</p>
        </li>
        <li><strong>Can Earnscape restore my assets if I lose my seed phrase?</strong>
          <p>No, only your seed phrase can restore your wallet. Losing it is akin to losing physical access to cash—there is no way to recover assets without it. Earnscape does not have access to your seed phrase and uses a third-party security service to process your transactions. This maintains your privacy and security. It also allows you to retain your wallet if you change devices.</p>
        </li>
        <li><strong>What happens if I lose access to my Earnscape account or lose my device?</strong>
          <p>You can recover your Earnscape account on a new device. If your account is suspended or banned, you can still log in and retrieve your seed phrase. If you request to delete your account, your seed phrase, wallet, and assets will be permanently lost.</p>
        </li>
      </ul>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  header: {
    fontSize: '2em',
    marginBottom: '10px',
    color: '#333',
  },
  subheader: {
    fontSize: '1.5em',
    marginTop: '20px',
    color: '#333',
  },
  highlight: {
    color: 'red',
    fontWeight: 'bold',
  },
  warningList: {
    color: '#d9534f',
  },
};

export default WalletEjectionGuide;
