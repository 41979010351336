import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
// import PrivateRoute from './Auth/privateRoutes'
import { createBrowserHistory } from "history";

import { AddGame, AddUser, GamePage, Profile, Settings } from "./pages";

import ForgetPassword from "./Auth/ForgetPassword";
import ForgetPasswordAuth from "./Auth/ForgetPasswordAuth";
import Suspended from "./Auth/Suspended";
import Login from "./Auth/login";
import PrivateRoute from "./Auth/privateRoutes";
import Signup from "./Auth/signup";
import Dashboard from "./pages/Dashboard";
import NotFound from "./pages/NotFound";
import TermsAndCondition from "./pages/TermsAndCondition";
import GameProfile from "./pages/GameProfilePage";
import NewTermsAndCondition from "./pages/NewTermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import WalletEjectionGuide from "./pages/walletEjectionGuide";

function App() {
  const history = createBrowserHistory();
  return (
    <div className="App">
      <BrowserRouter history={history}>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route path="/Signup" element={<Signup />} />
          {/* <Route path="*" elememnt={<NotFound />} /> */}
          <Route
            path="/home"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/game/:gameId"
            element={
              <PrivateRoute>
                <GamePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/addGame"
            element={
              <PrivateRoute>
                <AddGame />
              </PrivateRoute>
            }
          />
          <Route
            path="/addUser"
            element={
              <PrivateRoute>
                <AddUser />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile-settings"
            element={
              <PrivateRoute>
                <Settings />
              </PrivateRoute>
            }
          />
          <Route
            path="/forget-password"
            element={
              // <PrivateRoute>
              <ForgetPassword />
              // </PrivateRoute>
            }
          />
          <Route
            path={`/create-new-password/:paramsToken`}
            element={
              // <PrivateRoute>
              <ForgetPasswordAuth />
              // </PrivateRoute>
            }
          />
          <Route
            path={`/suspended`}
            element={
              // <PrivateRoute>
              <Suspended />
              // </PrivateRoute>
            }
          />
          <Route
            path="/terms-and-condition"
            element={
              // <PublicRoute>
              <TermsAndCondition />
              // </PublicRoute>
            }
          />
          <Route 
          path="/wallet-Ejection-Guide"
          element={
            <WalletEjectionGuide />
          }
          ></Route>
          <Route
            path="/new-terms-and-condition"
            element={
              // <PublicRoute>
              <NewTermsAndCondition />
              // </PublicRoute>
            }
          />
           <Route
            path="/privacyPolicy"
            element={
              // <PublicRoute>
              <PrivacyPolicy />
              // </PublicRoute>
            }
          />
          <Route
            path="/gameProfile"
            element={
              <GameProfile />
            }
          />
          <Route
            path="*"
            element={
              // <PublicRoute>
              <NotFound />
              // </PublicRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
